<template>
  <div class="login-view">
    <div class="content">
      <img src="@/assets/img/iconjama.png" alt="" />
      <div class="nav">
        <router-link :to="{ path: '/jama-bugbounty' }"
          ><i class="back flaticon-up-arrow"></i>Atrás</router-link
        >
        <router-link :to="{ path: navoption.path }"
          >{{navoption.option}}<i class=" login flaticon-up-arrow"></i
        ></router-link>
      </div>
      <div class="form">
        <form>
          <router-view />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "company-singup",
  computed:{
    navoption(){
      if(this.$route.name !== 'login'){
        return {
          path: '/login',
          option: 'Iniciar sesión'
        }
      }else{
        return{
          path: '/new-password',
          option: 'Recuperar Contraseña'
        }
      }
    }
  },
  mounted(){
    console.log(this.$route)
  }
};
</script>

<style lang="scss">
.login-view {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #f9f9ff 0%, #f9f9ff 47%, #efeff2 100%);
  .content {
    width: 1280px;
    padding: 60px 0;
    text-align: center;
    img {
      width: 150px;
      margin: 50px 0;
    }
    .nav {
      display: flex;
      justify-content: space-between;
      a {
        color: #222ac3;
        position: relative;
        i {
          position: absolute;
        }
        .back {
          left: 0;
          transform: rotate(270deg);
        }
        .login{
          right: 0;
          transform: rotate(-270deg);
        }
      }
    }
  }
}
</style>
